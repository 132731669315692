<template>
  <div class="line-page">
    <van-nav-bar
      :title="lineName"
      left-text="返回"
      right-text="首页"
      left-arrow
      @click-left="goBack"
      @click-right="goIndex"
    />
    <div v-show="!showMap">
      <div class="card">
        <div class="direct van-ellipsis">方向：{{ to }}</div>
        <div class="details">
          <div class="first-line van-ellipsis">
            <div class="first float-left">
              <img src="~@/assets/images/icon_first.png" class="icon" />
              <span class="time">{{ beginTime }}</span>
              <img src="~@/assets/images/icon_last.png" class="icon" />
              <span class="time">{{ endTime }}</span>
            </div>
            <div class="second float-left van-ellipsis" @click="showAll">
              <span>{{ commonts }}</span>
            </div>
            <div class="third float-right" @click="queryBusSchedule"><span class="schedule-list">时刻表</span></div>
          </div>
          <div class="second-line" v-if="planTime">
            <span>起点预计发车：</span>
            <span>{{ planTime }}</span>
          </div>
        </div>

        <div class="card-div">
          <div class="station-name van-ellipsis">{{ selectStationName }}</div>
          <div class="bus-distance">
            <div v-for="(bus, index) in buses" :key="index" class="float-left">
              <div>
                <span class="line-neartext">{{ bus.neartext }}</span>
              </div>
              <div>
                <span class="line-neardis">{{ bus.neardis }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line-list">
        <!-- 水平方向 -->
        <div class="horizontal-line">
          <ul ref="horizontalLine">
            <li v-for="(station, index) in stationList" :key="index" @click="changeStation(index, station.stationName)">
              <div class="line-station" :class="{ 'select-station': index == selectStationIndex - 1 }">
                <div class="line-bus">
                  <span v-if="getCome(index)" class="bus-coming">
                    <img src="~@/assets/images/route_icon_bus.png" class="icon-bus" />
                  </span>
                  <span v-if="getArrived(index)" class="bus-arrived">
                    <img src="~@/assets/images/route_icon_bus.png" class="icon-bus" />
                  </span>
                </div>
                <div class="line-line">
                  <div class="line-div" :class="getColor(index)">
                    <img src="~@/assets/images/route_icon_arrow.png" class="arrow" />
                  </div>
                  <div class="line-stops">
                    <span v-if="index == 0" class="stops">
                      <img src="~@/assets/images/icon_index_start.png" class="stops-img first-stops" />
                    </span>
                    <span v-else-if="index == stationList.length - 1" class="stops">
                      <img src="~@/assets/images/icon_index_end.png" class="stops-img last-stops" />
                    </span>
                    <span v-else-if="selectStationIndex == index + 1" class="stops">
                      <img src="~@/assets/images/line_icon_station.png" class="stops-img select-stops" />
                    </span>
                    <!-- <span class="stops">站</span> -->
                  </div>
                </div>
                <div class="line-station-num">
                  <span>{{ station.stationOrder }}</span>
                </div>
                <div class="line-station-name">
                  <span>{{ station.showName }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 垂直方向 -->
        <div class="vertical-line"></div>
      </div>
    </div>
    <div v-show="showMap">
      <div class="amap-div">
        <div id="container"></div>
      </div>
    </div>
    <div class="toggle-div" v-if="canShowMap">
      <button @click="toggleMap">地图</button>
    </div>
    <div class="turn-div">
      <div class="turn-to-button" @click="turnTo()">
        <van-icon name="exchange" color="#f99677" />
        <span class="hx">换向</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Toast, Dialog } from 'vant'
import { lineDetails, realtime } from '@/api/mygolbs'
import AMap from 'AMap'
import '@/assets/css/map.css'
export default {
  name: 'BusLine',
  props: ['lineName', 'upperOrDown', 'to'],
  data() {
    return {
      //地图参数start===================
      //定位完成后才能加载地图
      canShowMap: false,
      showMap: false,
      map: null,
      //显示在地图上的车
      mapBusMarker: {},
      //地图参数end==============
      //线路上的所有站点
      stationList: [],
      //各路段拥挤情况
      speedlist: [],
      //各站点距离
      dislist: [],
      //公交实时位置
      busLocationList: [],
      //实时公交
      busMap: {},
      //线路静态信息start==============
      //起止时间
      beginTime: '',
      endTime: '',
      //预计下次发车时间
      planTime: '',
      commonts: '',
      //查询时刻表用的 时刻表cmd119
      routeId: '',
      //线路静态信息end==============
      buses: [],
      selectStationName: this.stationName,
      reverseTo: '',
      //离我最近
      nearestStation: this.stationName,
      selectStationIndex: 0,
      cronJob: null,
    }
  },
  computed: {
    ...mapGetters(['location', 'stationName']),
  },
  activated() {
    this.init()
  },
  deactivated() {
    this.stopJob()
  },
  mounted() {},
  methods: {
    ...mapActions(['Position', 'turnToSchedule', 'turnToLine']),
    goBack() {
      this.$router.back()
    },
    goIndex() {
      this.$router.push({ name: 'Home' })
    },
    onClickRight() {
      Toast('按钮')
    },
    initMap: function () {
      var that = this
      let map = new AMap.Map('container', {
        // center: [119.29647, 26.07421],
        center: [this.location.lng, this.location.lat],
        rotateEnable: true,
        zoom: 14,
      })
      this.map = map
      AMap.plugin(['AMap.ToolBar', 'AMap.Scale', 'AMap.Geolocation'], function () {
        var options = {
          showButton: true, //是否显示定位按钮
          buttonPosition: 'LB', //定位按钮的位置
          /* LT LB RT RB */
          buttonOffset: new AMap.Pixel(10, 20), //定位按钮距离对应角落的距离
          showMarker: true, //是否显示定位点
          markerOptions: {
            //自定义定位点样式，同Marker的Options
            offset: new AMap.Pixel(-18, -36),
            content:
              '<img src="https://a.amap.com/jsapi_demos/static/resource/img/user.png" style="width:36px;height:36px"/>',
          },
          showCircle: true, //是否显示定位精度圈
          circleOptions: {
            //定位精度圈的样式
            strokeColor: '#0093FF',
            noSelect: true,
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: '#02B0FF',
            fillOpacity: 0.25,
          },
        }
        var geolocation = new AMap.Geolocation(options)
        map.addControl(geolocation)
        geolocation.getCurrentPosition()
        // map.addControl(new AMap.ToolBar())
        map.addControl(new AMap.Scale())
      })
      AMap.plugin(['AMap.LineSearch'], function () {
        //实例化公交线路查询类
        var linesearch = new AMap.LineSearch({
          pageIndex: 1, //页码，默认值为1
          pageSize: 20, //单页显示结果条数，默认值为20，最大值为50
          city: '福州市', //限定查询城市，可以是城市名（中文/中文全拼）、城市编码，默认值为『全国』
          extensions: 'all', //是否返回公交线路详细信息，默认值为『base』
        })

        //执行公交路线关键字查询
        linesearch.search(that.lineName, function (status, result) {
          //打印状态信息status和结果信息result
          console.log(status, result)
          if (status === 'complete' && result.info === 'OK') {
            var lineArr = result.lineInfo
            var lineNum = result.lineInfo.length
            if (lineNum > 0) {
              var i = that.upperOrDown - 1
              //路线
              var pathArr = lineArr[i].path
              //站点
              var stops = lineArr[i].via_stops

              for (i = 0; i < stops.length; i++) {
                //站点名称
                new AMap.Marker({
                  map: map,
                  title: stops[i].name + '-name',
                  position: stops[i].location,
                  zIndex: 9,
                  zooms: [16, 20],
                  label: {
                    offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
                    content: "<div class='info'>" + (i + 1) + '.' + stops[i].name + '</div>', //设置文本标注内容
                    direction: 'bottom', //设置文本标注方位
                  },
                })
              }
              var startPot = stops.shift().location
              var endPot = stops.pop().location

              // drawbusLine(startPot, endPot, pathArr);
              //起始站和终到站标记
              var startStop = new AMap.Marker({
                map: map,
                position: startPot, //基点位置
                icon: 'https://webapi.amap.com/theme/v1.3/markers/n/start.png',
                zIndex: 10,
                anchor: 'bottom-center',
              })
              var endStop = new AMap.Marker({
                map: map,
                position: endPot, //基点位置
                icon: 'https://webapi.amap.com/theme/v1.3/markers/n/end.png',
                zIndex: 10,
                anchor: 'bottom-center',
              })
              console.log(startStop, endStop)
              for (i = 0; i < stops.length; i++) {
                // 站点点位
                new AMap.Marker({
                  map: map,
                  // icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
                  title: stops[i].name + '-position',
                  position: stops[i].location,
                  zIndex: 10,
                })
              }
              var busPolyline = new AMap.Polyline({
                map: map,
                path: pathArr,
                strokeColor: '#09f', //线颜色
                strokeOpacity: 0.8, //线透明度
                isOutline: true,
                outlineColor: 'white',
                strokeWeight: 6, //线宽
              })
              console.log(busPolyline)
              // map.setFitView([startStop, endStop], false, [10, 10, 10, 10])
            }
          }
        })
      })
    },
    moveBus(list) {
      var that = this
      console.log('巴士信息', list)

      AMap.plugin('AMap.MoveAnimation', function () {
        for (let j = 0; j < list.length; j++) {
          const bus = list[j]
          var key = bus.busNumber
          var mapBus = that.mapBusMarker[key]
          // console.log(that.mapBusMarker, mapBus)
          // console.log('bus dingwei ', bus.bus_lng, bus.bus_lat)

          if (mapBus) {
            mapBus.moveTo([bus.bus_lng, bus.bus_lat], 30)
          } else {
            var marker = new AMap.Marker({
              map: that.map,
              //119.38815706454209 26.02776188385207
              position: [bus.bus_lng, bus.bus_lat],
              icon: 'https://webapi.amap.com/images/car.png',
              offset: new AMap.Pixel(-26, -13),
              autoRotation: true,
              angle: bus.angle + 90,
            })
            // console.log(marker)
            that.mapBusMarker[key] = marker
          }
        }
      })
    },
    showAll() {
      Dialog.alert({
        title: '提示信息',
        message: this.commonts,
        // theme: 'round-button',
      })
    },
    getCome(index) {
      var item = this.busMap[index]
      if (item) {
        return item.come
      } else {
        return 0
      }
    },
    getArrived(index) {
      var item = this.busMap[index]
      if (item) {
        return item.arrive
      } else {
        return 0
      }
    },
    getColor(index) {
      var item = this.speedlist[index]
      if (item) {
        return item.co
      } else {
        return ''
      }
    },
    //切换地图显示
    toggleMap() {
      var isShow = this.showMap
      this.showMap = !isShow
    },
    //查询反向车
    turnTo() {
      //方向1、2互换
      let upperOrDown = this.upperOrDown ^ 3
      // this.upperOrDown = upperOrDown
      // console.log(upperOrDown)
      let params = { lineName: this.lineName, upperOrDown: this.upperOrDown, to: this.reverseTo }
      this.turnToLine(params)
      this.$router.push({
        name: 'BusLine',
        params: { lineName: this.lineName, upperOrDown: upperOrDown, to: this.reverseTo },
      })
    },
    init() {
      //初始化数据
      //如果参数为空，则
      this.Position()
        .then(res => {
          if (res.status == 200) {
            console.log('canShowMap = true')
            this.canShowMap = true
            this.initMap()
            this.queryLineDetails().then(() => {
              console.log('????????')
              this.queryRealTime()
              this.startJob()
            })
          }
        })
        .catch(error => {
          Toast(error)
        })
    },
    startJob() {
      this.$nextTick(() => {
        //定时任务 10秒一次
        this.cronJob = setInterval(this.queryRealTime, 10000)
      })
    },
    stopJob() {
      console.log('stop job')
      clearInterval(this.cronJob)
    },
    async queryLineDetails() {
      console.log('queryLineDetails')
      console.log('this.location', this.location)
      await lineDetails(this.lineName, this.upperOrDown, this.location.city).then(res => {
        console.log(res)
        //获取线路信息
        //1.firstLast
        this.beginTime = res.beginTime
        this.endTime = res.endTime
        this.planTime = res.planTime
        this.commonts = res.commonts
        this.routeId = res.routeId
        //线路上所有的点
        this.stationList = res.data
        //起点站即反向第一站
        this.reverseTo = res.data[0].stationName
        //计算离我最近的站点
        console.log('this.nearestStation', this.nearestStation)
        //为点击进来时的站点,如果没有，则计算距离
        if (this.nearestStation) {
          console.log('????????zheli1')
          for (var station of this.stationList) {
            // console.log(station.stationName, this.nearestStation, station.stationName == this.nearestStation)
            if (station.stationName == this.nearestStation) {
              this.selectStationIndex = station.stationOrder
            } else {
              this.nearestStation = null
            }
          }
        }
        if (!this.nearestStation) {
          console.log('????????zheli2')
          var nearest = {}
          var dis
          for (var station2 of this.stationList) {
            var station_lat = station2.station_lat
            var station_lon = station2.station_lon
            this.location.lat
            this.location.lng
            //计算距离
            var d = Math.pow(
              (station_lat - this.location.lat) * (station_lat - this.location.lat) +
                (station_lon - this.location.lng) * (station_lon - this.location.lng),
              0.5,
            )
            if (!dis || d < dis) {
              dis = d
              nearest = station2
            }
          }
          this.nearestStation = nearest.stationName
          this.selectStationName = nearest.stationName
          this.selectStationIndex = nearest.stationOrder
          console.log(123123, this.selectStationName, this.nearestStation, this.selectStationIndex)

          //修改滚动位置
          this.changeScrollLeft(this.selectStationIndex - 1)
        }
        //关联地铁
        res.StationRelatedRouteName
      })
    },
    scroll() {
      console.log('当前位置：', this.$refs.horizontalLine.scrollLeft)
    },
    changeStation(index, stationName) {
      //修改滚动位置
      this.changeScrollLeft(index)
      //修改显示位置，修改站点显示
      console.log(index, stationName)
      this.selectStationIndex = index + 1
      this.selectStationName = stationName
      this.calBusesCard(this.busLocationList, this.dislist)
    },
    changeScrollLeft(index) {
      this.$nextTick(() => {
        console.log('gundongjuli', index * 60)
        console.log(this.$refs.horizontalLine.offsetWidth)
        this.$refs.horizontalLine.scrollLeft = (index + 1) * 60 - this.$refs.horizontalLine.offsetWidth / 2
      })
    },
    queryRealTime() {
      console.log('queryRealTime')
      realtime(this.lineName, this.upperOrDown, this.location.city).then(res => {
        console.log('实时车辆信息', res)
        //data
        var busMap = {}
        res.data.forEach(item => {
          busMap[item.index] = item
        })
        this.busMap = busMap
        //各站点间距离
        this.dislist = res.dislist
        //公交车实时位置
        this.busLocationList = res.list
        this.moveBus(res.list)
        //各路段拥挤情况
        this.speedlist = res.speedlist
        this.calBusesCard(res.list, res.dislist)
      })
    },
    //计算到站信息
    calBusesCard(busList, dislist) {
      if (busList.length > 0) {
        var buses = []
        for (var i = busList.length - 1; i >= 0; i--) {
          var bus = busList[i]
          // console.log('当前站点', this.selectStationIndex, bus.index)
          if (this.selectStationIndex < bus.index + 1) {
            continue
          }
          var distance = bus.busToStationNiheDistance
          for (var j = bus.index; j < this.selectStationIndex - 1; j++) {
            distance += dislist[j].d
          }
          distance = Math.round(distance)
          // console.log(distance)
          var num = this.selectStationIndex - bus.index
          if (bus.statusType == 0) {
            num--
          }
          var neartext = '等待发车'
          if (distance == 0) {
            neartext = '已到站'
          } else {
            neartext = num == 1 ? '即将到站' : num + '站'
          }
          var nearDis = distance == 0 ? '' : this.calTime(distance) + '/' + this.calDistance(distance)
          var busDis = { neartext: neartext, neardis: nearDis }
          buses.push(busDis)
          if (buses.length >= 2) {
            break
          }
        }
        // console.log('buses', buses)
        if (buses.length == 0) {
          buses.push({ neartext: '等待发车' })
        }
        this.buses = buses
      } else {
        // console.log('没有车')
        this.buses = [{ neartext: '等待发车...' }]
      }
    },
    calDistance(dis) {
      if (dis < 1000) {
        return Math.round(dis) + 'm'
      } else {
        return (dis / 1000).toFixed(1) + 'km'
      }
    },
    calTime(dis) {
      if (dis < 300) {
        return '小于1分'
      } else {
        return Math.ceil(dis / 400) + '分'
      }
    },
    queryBusSchedule() {
      console.log('?????????')
      var params = {
        routeId: this.routeId,
        beginTime: this.beginTime,
        endTime: this.endTime,
        planTime: this.planTime,
        commonts: this.commonts,
      }
      this.turnToSchedule(params)
      this.$router.push({
        name: 'BusSchedule',
        params: { lineName: this.lineName, routeId: this.routeId, upperOrDown: this.upperOrDown, to: this.to },
      })
    },
    selectStation() {
      //当站点被点击时
      //1.变色，居中、公交车距离站点的时间和距离计算
    },
  },
}
</script>
<style lang="scss" scoped>
.card {
  margin: 20px;
  .direct {
    height: 20px;
  }
  .details {
    padding-top: 5px;
    padding-bottom: 15px;
    .first-line {
      height: 20px;
    }
    .second-line {
      height: 15px;
      font-size: 14px;
    }
    .icon {
      height: 15px;
    }
    .time {
      padding-left: 2px;
      padding-right: 10px;
      font-size: 10px;
    }
    .float-left {
      float: left;
    }
    .float-right {
      float: right;
    }
    .first {
      height: 20px;
    }
    .second {
      width: calc(100vw - 220px);
      font-size: 14px;
    }
    .third {
      font-size: 14px;
      color: #2ab167;
      .schedule-list {
        padding-right: 20px;
      }
    }
  }
}
.card-div {
  height: 85px;

  border: 1px solid #dadce0;
  border-radius: 8px;
  background-image: url(~@/assets/images/route_bg_map3x.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 35% 100%;
  padding: 15px 20px;
  .station-name {
    font-size: 20px;
  }
  .bus-distance {
    margin-top: 16px;
  }
}
.bus-distance {
  .float-left {
    float: left;
    min-width: 100px;
    width: 30%;
    .line-neartext {
      color: #f99677;
      font-size: 18px;
      line-height: 20px;
      height: 20px;
    }
    .line-neardis {
      color: #686868;
      font-size: 14px;
      line-height: 20px;
      height: 20px;
    }
  }
}
.line-list {
  height: 270px;
  border: solid 1px;
  padding: 0 10px;
  * {
    padding: 0;
    margin: 0;
  }
  .horizontal-line {
    width: 100%;
    overflow: hidden;
    ul {
      display: block;
      padding: 0;
      white-space: nowrap;
      overflow-x: scroll;
    }
    ul li {
      width: 60px;
      text-align: center;
      // background: #00ff00 repeat-x 0 17px;
      vertical-align: top;
      list-style: none;
      white-space: nowrap;
      width: 60px;
      display: inline-block;
      .line-station {
        .line-bus {
          margin-top: 5px;
          height: 15px;
          // background-color: #edf6ff;
          .bus-coming {
            position: relative;
            left: -28px;
          }
          .bus-arrived {
            position: relative;
            left: 00px;
          }
          .icon-bus {
            height: 15px;
          }
        }
        .line-line {
          height: 15px;
          position: relative;
          left: 30px;
          .line-div {
            margin: 10px 0;
            height: 5px;
            background: url(~@/assets/images/route_icon_arrow.png) no-repeat;
            background-size: 4px;
            .arrow {
              height: 5px;
              float: left;
            }
          }
          .green {
            background-color: #22c86d;
          }
          .orange {
            background-color: #ff9c2c;
          }
          .red {
            background-color: #fb572a;
          }
          .line-stops {
            .first-stops {
              position: relative;
              left: -30px;
              top: -23px;
              height: 20px;
            }
            .last-stops {
              position: relative;
              left: -30px;
              top: -23px;
              height: 20px;
            }
            .select-stops {
              position: relative;
              left: -30px;
              top: -30px;
              height: 35px;
            }
          }
        }
        .line-station-num {
          height: 20px;
          span {
            font-size: 8px;
          }
        }
        .line-station-name {
          height: 200px;
          padding-top: 5px;
          span {
            white-space: normal;
            display: block;
            width: 18px;
            margin: auto;
            color: #000;
            line-height: 18px;
            overflow: hidden;
          }
        }
      }
    }
  }
}
.select-station {
  span {
    color: #ff9c2c !important;
  }
}
#container {
  height: 600px;
  width: 100%;
}

.toggle-div {
  position: absolute;
  // width: 100px;
  // height: 50px;
  background: pink;
  left: 50%; //起始是在body中，横向距左50%的位置
  top: 80%; //起始是在body中，纵向距上50%的位置，这个点相当于body的中心点，div的左上角的定位
  transform: translate(-50%, -50%); //水平、垂直都居中,也可以写成下面的方式
  z-index: 10;
}
.turn-div {
  position: absolute;
  left: 50%; //起始是在body中，横向距左50%的位置
  bottom: 20px;
  transform: translate(-50%, -50%); //水平、垂直都居中,也可以写成下面的方式
  z-index: 10;
  .turn-to-button {
    border: 1px solid #f99677;
    width: 78px;
    padding: 4px 17px 4px 37px;
    border-radius: 15px;
    .hx {
      color: #f99677;
      font-size: 18px;
      line-height: 20px;
      height: 20px;
    }
  }
}
</style>
